var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));

let customerChildren = [
    {
        title: "Customers Management",
        route: "all-customers",
    },
];

if (userData.roles[0].name === "Super_Admin") {
    customerChildren.push({
        title: "Users Management",
        route: "all-users",
    });
}

let offersChildren = [
    {
        title: "Offers",
        route: "all-offers",
    },
    {
        title: "Items Management",
        route: "all-items",
    },
    {
        title: "Colors Management",
        route: "all-colors",
    },
    {
        title: "Additions Management",
        route: "all-additions",
    },
];

if (userData.roles[0].name === "Super_Admin") {
    offersChildren.push({
        title: "Offers (deleted)",
        route: "all-offers-removed",
    });
}

items = [
    {
        title: "Offers",
        icon: "HomeIcon",
        route: "all-offers",
        children: offersChildren,
    },
    {
        title: "Customers",
        icon: "UsersIcon",
        route: "all-customers",
        children: customerChildren,
    },
    {
        title: "Projects",
        icon: "FileIcon",
        route: "all-projects",
    },
    {
        title: "Sales Tickets",
        icon: "TrelloIcon",
        route: "sales-tickets",
    },
];

export default items;
